<template>
    <div class="row">
        <div class="col-md-6 mb-lg-0 mb-4">
            <div class="card mt-4">
                <div class="card-header pb-0 p-3">
                    <div class="row">
                        <div class="col-6 d-flex align-items-center">
                            <h6 class="mb-0">Akun SIMASN</h6>
                        </div>
                        <div class="col-6 text-end">
                            <button :disabled="savingAkunSim" class="btn bg-gradient-dark mb-0" @click="saveAkunSim">
                                <span v-if="savingAkunSim" class="material-icons spin-animate">
                                    hourglass_bottom
                                </span>
                                <i class="material-icons text-sm">save</i> Simpan
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body p-3">
                    <div class="row">
                        <div class="col">
                            <label class="form-label">Username</label>
                            <div class="input-group input-group-outline mb-3">
                                <input v-model="akunSimAsn.username" type="text" class="form-control" placeholder="username">
                            </div>
                            <label class="form-label">Password</label>
                            <div class="input-group input-group-outline mb-3">
                                <input v-model="akunSimAsn.password" type="password" class="form-control" placeholder="password">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card mt-4">
                <div class="card-header pb-0 p-3">
                    <div class="row">
                        <div class="col-6 d-flex align-items-center">
                            <h6 class="mb-0">Format No Surat</h6>
                        </div>
                        <div class="col-6 text-end">
                            <button :disabled="savingFormatSurat" class="btn bg-gradient-dark mb-0" @click="saveFormatSurat">
                                <span v-if="savingFormatSurat" class="material-icons spin-animate">
                                    hourglass_bottom
                                </span>
                                <i class="material-icons text-sm">save</i> Simpan
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body p-3">
                    <div class="row">
                        <div class="col">
                            <label class="form-label">
                                Format Surat
                                (<code>Param : {bulan} = Bulan,{tahun} = Tahun</code>)
                            </label>
                            <div class="input-group input-group-outline mb-3">
                                <input v-model="formatSurat" type="text" class="form-control" placeholder="Format Surat">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 mb-lg-0 mb-4">
            <div class="card mt-4">
                <div class="card-header pb-0 p-3">
                    <div class="row">
                        <div class="col-6 d-flex align-items-center">
                            <h6 class="mb-0">Default Pejabat Pengesah</h6>
                        </div>
                        <div class="col-6 text-end">
                            <button :disabled="savingPejabat" class="btn bg-gradient-dark mb-0" @click="savePejabat">
                                <span v-if="savingPejabat" class="material-icons spin-animate">
                                    hourglass_bottom
                                </span>
                                <i class="material-icons text-sm">save</i> Simpan
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body p-3">
                    <div class="row">
                        <div class="col">
                            <label class="form-label">Jabatan</label>
                            <div class="input-group input-group-outline mb-3">
                                <input v-model="pejabat.jabatan" type="text" class="form-control" placeholder="Jabatan">
                            </div>
                            <label class="form-label">Nama</label>
                            <div class="input-group input-group-outline mb-3">
                                <input v-model="pejabat.nama" type="text" class="form-control" placeholder="Nama">
                            </div>
                            
                            <label class="form-label">NIP</label>
                            <div class="input-group input-group-outline mb-3">
                                <input v-model="pejabat.nip" type="text" class="form-control" placeholder="NIP">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import Swal from 'sweetalert2';

export default {
    name: 'SettingsPage',
    props: {
        msg: String
    },
    data() {
        return {
            savingAkunSim: false,
            savingPejabat:false,
            savingFormatSurat:false,
            akunSimAsn:{
                username:null,
                password:null,
            },
            pejabat:{
                jabatan:null,
                nip:null,
                nama:null,
            },
            formatSurat:null,
        }
    },
    mounted(){
        this.fetchSettings();
    },
    methods: {
        fetchSettings(){
            Swal.fire({
                allowOutsideClick:false,
                html:`<h3><span class="material-icons spin-animate" style="font-size:40px">hourglass_bottom</span><br/>Loading Data....</h3>`,
                showConfirmButton:false,
            })
            axios.get("/api/settings").then( response => {
                response.data.forEach(row => {
                    if(row.key == 'pejabat')
                        this.pejabat = row.value;
                    if(row.key == 'akun_simasn')
                        this.akunSimAsn = row.value;
                    if(row.key == 'format_surat')
                        this.formatSurat = row.value;
                });
                Swal.close();
            })
        },
        savePejabat() {
            this.savingPejabat = true;
            axios.post('/api/settings',{key:'pejabat',value:this.pejabat}).then(response => {
                console.log(response);
                Swal.fire("Berhasil", "Pengaturan berhasil disimpan", 'success');

            }).finally(() => {
                this.savingPejabat = false;
            })
        },
        saveAkunSim() {
            this.savingAkunSim = true;
            axios.post('/api/settings',{key:'akun_simasn',value:this.akunSimAsn}).then(response => {
                console.log(response);
                Swal.fire("Berhasil", "Pengaturan berhasil disimpan", 'success');
            }).finally(() => {
                this.savingAkunSim = false;
            })
        },
        saveFormatSurat() {
            this.savingFormatSurat = true;
            axios.post('/api/settings',{key:'format_surat',value:this.formatSurat}).then(response => {
                console.log(response);
                Swal.fire("Berhasil", "Pengaturan berhasil disimpan", 'success');
            }).finally(() => {
                this.savingFormatSurat = false;
            })
        }
    }
}
</script>
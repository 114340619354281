<template>
  <div>
    <div class="row">
      <div class="col-xl-4 col-sm-6 mb-xl-0 mb-4">
        <div class="card">
          <div class="card-header p-3 pt-2">
            <div
              class="icon icon-lg icon-shape bg-gradient-success shadow-dark text-center border-radius-xl mt-n4 position-absolute"
            >
              <i class="material-icons opacity-10">group</i>
            </div>
            <div class="text-end pt-1">
              <p class="text-sm mb-0 text-capitalize">Jumlah Pegawai</p>
              <h4 class="mb-0">{{ rekap.jumlahPegawai }} Pegawai</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-sm-6 mb-xl-0 mb-4">
        <div class="card">
          <div class="card-header p-3 pt-2">
            <div
              class="icon icon-lg icon-shape bg-gradient-success shadow-dark text-center border-radius-xl mt-n4 position-absolute"
            >
              <i class="material-icons opacity-10">account_balance_wallet</i>
            </div>
            <div class="text-end pt-1">
              <p class="text-sm mb-0 text-capitalize">Akan Naik Gaji Berkala</p>
              <h4 class="mb-0">{{ rekap.akanNaikGaji }} Pegawai</h4>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                <div class="card">
                    <div class="card-header p-3 pt-2">
                        <div
                            class="icon icon-lg icon-shape bg-gradient-success shadow-success text-center border-radius-xl mt-n4 position-absolute">
                            <i class="material-icons opacity-10">multiline_chart</i>
                        </div>
                        <div class="text-end pt-1">
                            <p class="text-sm mb-0 text-capitalize">Akan Naik Pangkat</p>
                            <h4 class="mb-0">- Pegawai</h4>
                        </div>
                    </div>
                    <hr class="dark horizontal my-0">
                    <div class="card-footer p-3">
                        <p class="mb-0">Total tahun {{year}} : <span class="text-success font-weight-bolder">-</span> Pegawai
                        </p>
                    </div>
                </div>
            </div> -->
      <div class="col-xl-4 col-sm-6 mb-xl-0 mb-4">
        <div class="card">
          <div class="card-header p-3 pt-2">
            <div
              class="icon icon-lg icon-shape bg-gradient-success shadow-success text-center border-radius-xl mt-n4 position-absolute"
            >
              <i class="material-icons opacity-10">done</i>
            </div>
            <div class="text-end pt-1">
              <p class="text-sm mb-0 text-capitalize">Telah Diusulkan</p>
              <h4 class="mb-0">
                {{ rekap.telahDiusulkan }}
                /
                {{ rekap.naikGaji }} Pegawai
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="row bg-gradient-success shadow-success border-radius-lg pt-3 pb-1"
            >
              <div class="col-6">
                <h6 class="text-white text-capitalize ps-3">
                  Informasi Early Warning Pegawai
                </h6>
              </div>
              <div
                class="col-md-6 d-flex justify-content-start justify-content-md-end align-items-center"
              >
                <h6 class="text-white text-capitalize ps-3">
                  <i class="material-icons me-2 text-lg">date_range</i>
                  Tahun
                  <select v-model="year">
                    <option :value="new Date().getFullYear()">
                      {{ new Date().getFullYear() }}
                    </option>
                    <option :value="new Date().getFullYear() + 1">
                      {{ new Date().getFullYear() + 1 }}
                    </option>
                  </select>
                </h6>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <div class="table-responsive p-0">
              <div
                v-if="loading"
                class="text-center"
                style="position: absolute; width: 100%; top: 45%"
              >
                <div
                  class="card bg-secondary"
                  style="margin: auto; width: 300px"
                >
                  <div class="card-body text-light">
                    <i class="fa fa-spin fa-spinner fa-3x"></i>
                    <h4 class="text-light">Loading...</h4>
                  </div>
                </div>
              </div>
              <ul
                ref="listInfo"
                class="list-group list-group-flush scroll-list"
                :style="infoStyle"
              >
                <li
                  v-for="row in dataWarning"
                  :key="row.nip + row.tipe"
                  class="list-group-item d-flex"
                >
                  <div class="col-6">
                    <div class="fw-bold">{{ row.nama }}</div>
                    NIP. {{ row.nip }} <br />
                    {{ row.pangkat }} , {{ row.pkt_golongan }}/{{ row.ruang }}
                  </div>
                  <div
                    class="col-3 d-flex flex-column justify-content-center align-items-center"
                  >
                    <span
                      class="badge badge-sm"
                      :class="[
                        row.tipe == 1
                          ? 'bg-gradient-success'
                          : 'bg-gradient-primary',
                      ]"
                      >{{ row.tipe == 1 ? "Naik Gaji" : "Naik Pangkat" }}</span
                    >
                    <span>Bulan {{ formatNext(row.selanjutnya) }}</span>
                  </div>
                  <div
                    class="col-3 d-flex flex-column justify-content-center align-items-center"
                  >
                    <span
                      class="badge badge-sm"
                      :class="statusClass(row.status)"
                      >{{ row.status }}</span
                    >
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.scroll-list {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
}
</style>
<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "EarlyWarningPage",
  props: {
    msg: String,
  },
  data: () => {
    return {
      dataWarning: [],
      page: 1,
      countData: 0,
      rekap: {},
      limit: 1000,
      loading: false,
      year: new Date().getFullYear(),
      looper: null,
      infoStyle: { "max-height": "100px" },
    };
  },
  watch: {
    year() {
      this.fetchData();
    },
  },
  computed: {
    lastPage() {
      return Math.ceil(this.countData / this.limit);
    },
  },
  mounted() {
    this.fetchData();
    let y = this.$refs.listInfo.getBoundingClientRect().y;
    this.infoStyle = { "max-height": `${window.innerHeight - y - 25}px` };
    let pause = false;
    let lastPos = -1;
    this.looper = setInterval(() => {
      if (pause == false) {
        this.$refs.listInfo.scrollBy(0, 3);
      }
      if (
        this.$refs.listInfo.scrollTop + this.$refs.listInfo.clientHeight >=
          this.$refs.listInfo.scrollHeight ||
        (lastPos == this.$refs.listInfo.scrollTop && lastPos != 0)
      ) {
        pause = true;
        setTimeout(() => {
          pause = false;
        }, 1000);
        this.$refs.listInfo.scrollTo({ top: 0, behavior: "smooth" });
      }
      lastPos = this.$refs.listInfo.scrollTop;
    }, 150);
    // this.runAuto();
  },
  beforeDestroy() {
    if (this.looper) {
      clearInterval(this.looper);
    }
  },
  methods: {
    statusClass(status) {
      let className = "bg-info";
      if (status.toLowerCase() == "akan diproses") {
        className = "bg-gradient-danger";
      } else if (status.toLowerCase() == "dalam proses") {
        className = "bg-gradient-warning";
      }
      return className;
    },
    setPage(newPage) {
      this.page = newPage;
      this.fetchData();
    },
    fetchData() {
      this.loading = true;
      axios
        .get(
          `/api/notify?year=${this.year}&limit=${this.limit}&page=${this.page}`
        )
        .then((response) => {
          this.dataWarning = response.data.data;
          this.countData = response.data.count;
          this.rekap = response.data.rekap;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatNotif(date) {
      return moment(date).format("DD MMM YYYY hh:mm:ss");
    },
    formatNext(date) {
      return moment(date).format("MMMM YYYY");
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="card my-4">
        <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
          <div class="row bg-gradient-success shadow-success border-radius-lg pt-4 pb-3">
            <span class="col-6">
              <h6 class="text-white text-capitalize ps-3">
                Riwayat Sinkronisasi
              </h6>
            </span>
            <span class="col-6 text-end">
              <button @click="newSync" class="btn bg-gradient-dark mb-0">
                <i class="material-icons text-sm">sync</i> Sinkronisasi Baru
              </button>
            </span>
          </div>
        </div>
        <div class="card-body px-0 pb-2">
          <div class="table-responsive p-0">
            <table class="table align-items-center mb-0">
              <thead>
                <tr>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                    Tanggal Sinkron
                  </th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                    Langkah
                  </th>
                  <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                    Status
                  </th>
                  <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                    Aksi
                  </th>
                  <th class="text-secondary opacity-7"></th>
                </tr>
              </thead>
              <tbody v-if="loading">
                <tr>
                  <td colspan="4" class="text-center p-5">
                    <span class="material-icons spin-animate">
                      hourglass_bottom
                    </span>
                    <p>Loading Data....</p>
                  </td>
                </tr>
              </tbody>
              <tbody v-if="!loading">
                <tr v-for="(row, ind) in history" :key="row.id">
                  <td>
                    <div class="d-flex px-2 py-1">
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-0 text-sm">
                          {{ formatDate(row.tanggal) }}
                        </h6>
                      </div>
                    </div>
                  </td>
                  <td>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item">
                        Ambil Daftar Pegawai
                        <span v-if="row.step.daftar.status == 'onprogress'" class="material-icons spin-animate">
                          hourglass_bottom
                        </span>
                        <span v-if="row.step.daftar.status == 'finish'" class="badge bg-success material-icons"
                          style="font-size: 0.75em">
                          check
                        </span>
                        <span v-if="row.step.daftar.status == 'failed'" class="badge bg-danger material-icons"
                          style="font-size: 0.75em">
                          close
                        </span>
                      </li>
                      <li class="list-group-item">
                        Sync Data Pegawai
                        <span v-if="row.step.pegawai.status == 'onprogress'" class="material-icons spin-animate">
                          hourglass_bottom
                        </span>
                        <span v-if="row.step.pegawai.status == 'finish'" class="badge bg-success material-icons"
                          style="font-size: 0.75em">
                          check
                        </span>
                        <div class="progress mt-2" style="height: 15px; width: 100%">
                          <div class="progress-bar bg-gradient-success" role="progressbar" :style="row.step.pegawai.style"
                            style="height: 15px" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100">
                            {{ row.step.pegawai.style.width }}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </td>
                  <td class="align-middle text-center text-sm">
                    <span class="badge badge-sm bg-gradient-success" :class="{
                      'bg-gradient-success': row.status != 'Gagal',
                      'bg-gradient-danger': row.status == 'Gagal',
                    }">
                      {{ row.status }}
                    </span>
                  </td>
                  <td class="align-middle text-center">
                    <button @click="deleteSync(row, ind)" class="btn btn-danger">
                      <i v-if="deletingIndex != ind" class="material-icons">delete</i>
                      <span v-else class="material-icons spin-animate">
                        hourglass_bottom
                      </span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade show" style="z-index: 10000;" id="modal-progress" data-bs-backdrop="static"
      data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Sync Data</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <pre class="pre-log bg-dark text-light">{{ log }}</pre>
          <div class="modal-footer">
            <button v-if="currentSync.status == 'Berhasil'" type="button" class="btn btn-secondary"
              data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.pre-log {
  margin: 0px;
  padding: 5px;
}
</style>
<script>
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";
const bootstrap = require("bootstrap");

export default {
  name: "SyncPage",
  data() {
    return {
      loading: false,
      history: [],
      currentSync: { status: "" },
      deletingIndex: -1,
      modal: null,
      log: "",
    };
  },
  props: {
    msg: String,
  },
  mounted() {
    this.fetchHistory();
    this.modal = new bootstrap.Modal(
      document.getElementById("modal-progress"),
      { backdrop: "static" }
    );
  },
  methods: {
    deleteSync(row, ind) {
      this.deletingIndex = ind;
      Swal.fire({
        title: "Anda Yakin ?",
        text: "Anda akan menghapus history sync ini ?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
      }).then((result) => {
        if (result.isConfirmed) {
          axios.delete(`/api/sync/${row.id}`).then((response) => {
            if (response) {
              this.fetchHistory();
            }
            this.deletingIndex = -1;
          });
        } else {
          this.deletingIndex = -1;
        }
      });
    },
    formatDate(date) {
      return moment(date).format("DD MMM YYYY h:mm:ss a");
    },
    fetchHistory() {
      this.loading = true;
      axios
        .get("/api/sync")
        .then((response) => {
          if (response.data.status == 200) {
            this.history = response.data.data;
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    syncPegawai() {
      if (
        this.currentSync.step.pegawai.list.length > 0 ||
        this.currentSync.step.pegawai.current.step.length > 0
      ) {
        this.currentSync.step.pegawai.status = "onprogress";
        if (this.currentSync.step.pegawai.current.step.length == 0) {
          this.currentSync.step.pegawai.current =
            this.currentSync.step.pegawai.list.pop();
          this.log = this.log.concat(
            `Crawling data atas nama ${this.currentSync.step.pegawai.current.nama}, NIP.${this.currentSync.step.pegawai.current.nip}\n`
          );
        }
        let step = this.currentSync.step.pegawai.current.step.pop();
        this.log = this.log.concat(`- Fetch data ${step}...\n`);
        if (step == "kepangkatan") {
          this.log = this.log.concat(`- Crawl : https://simasn.pertanian.go.id/simasn/pegawai/pangkat/index/${this.currentSync.step.pegawai.current.nip}`);
        } else {
          this.log = this.log.concat(`- Crawl : https://simasn.pertanian.go.id/simasn/pegawai/datadasar/user/${this.currentSync.step.pegawai.current.nip}`);
        }
        axios
          .get(`/api/sync/${this.currentSync.step.pegawai.current.nip}/${step}`)
          .then((response) => {
            console.log(response);
          })
          .then(() => {
            let width =
              ((this.currentSync.step.pegawai.count -
                this.currentSync.step.pegawai.list.length) /
                this.currentSync.step.pegawai.count) *
              100;
            this.currentSync.step.pegawai.style.width = Math.round(width) + "%";
            this.log = this.log.concat(`...data berhasil diambil dan tersimpan\n`);
            this.syncPegawai();
          })
          .catch(() => {
            let width =
              ((this.currentSync.step.pegawai.count -
                this.currentSync.step.pegawai.list.length) /
                this.currentSync.step.pegawai.count) *
              100;
            this.currentSync.step.pegawai.style.width = Math.round(width) + "%";
            this.log = this.log.concat(`...gagal ambil data\n`);
            this.syncPegawai();
          });
      } else {
        this.currentSync.step.pegawai.status = "finish";
        this.currentSync.status = "Berhasil";
        axios.put(`/api/sync/${this.currentSync.id}`, this.currentSync);
      }
    },
    newSync() {
      this.modal.show();
      this.log = "Mulai crawling data...\n";
      this.log = this.log.concat("Mengambil daftar pegawai...\n");
      this.log = this.log.concat("Crawl : https://simasn.pertanian.go.id/simasn/pegawai/datadasar");
      this.currentSync = {
        id: this.history.length + 1,
        tanggal: moment().format("YYYY-MM-DD HH:mm:ss"),
        status: "Dalam Proses",
        step: {
          daftar: {
            status: "onprogress",
          },
          pegawai: {
            status: "waiting",
            count: 0,
            current: { step: [] },
            list: [],
            style: { width: "0%" },
          },
        },
      };
      axios
        .post("/api/sync", this.currentSync)
        .then((response) => {
          if (response.data.status == 200) {
            this.currentSync.step.daftar.status = "finish";
            this.currentSync.id = response.data.data.id;
            this.currentSync.step.pegawai.count =
              response.data.data.list.length;
            let listPegawai = [];
            response.data.data.list.forEach((item) => {
              let temp = item;
              temp.step = item.step.split(";");
              listPegawai.push(temp);
            });
            this.currentSync.step.pegawai.list = listPegawai;
            this.log = this.log.concat(
              `...jumlah pegawai ${listPegawai.length}\n`
            );
            this.log = this.log.concat(
              "Mulai crawling data pegawai perorang....\n"
            );
            this.syncPegawai();
          } else {
            this.currentSync.step.daftar.status = "failed";
            alert(response.data.messages);
          }
        })
        .catch((ex) => {
          alert(ex.message);
          this.currentSync.step.daftar.status = "failed";
        });
      this.history.unshift(this.currentSync);
    },
  },
};
</script>

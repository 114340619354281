<template>
  <section>
    <Transition name="slide-fade">
      <aside
        v-if="showMenu"
        class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 bg-gradient-dark ps bg-white"
        id="sidenav-main"
      >
        <div class="sidenav-header">
          <i
            class="fas fa-times p-3 cursor-pointer text-white opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
            aria-hidden="true"
            id="iconSidenav"
          ></i>
          <a
            class="navbar-brand m-0 d-flex align-items-center"
            href=" https://demos.creative-tim.com/material-dashboard/pages/dashboard "
            target="_blank"
          >
            <img
              src="img/logo.png"
              class="navbar-brand-img h-100"
              alt="main_logo"
            />
            <span class="ms-1 font-weight-bold text-white ms-2"
              >Early Warning <br />
              Kenaikan Gaji Berkala
            </span>
          </a>
        </div>
        <hr class="horizontal light mt-0 mb-2" />
        <div
          class="collapse navbar-collapse w-auto"
          style="height: 100%"
          id="sidenav-collapse-main"
        >
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link class="nav-link text-white" to="/early_warning">
                <div
                  class="text-white text-center me-2 d-flex align-items-center justify-content-center"
                >
                  <i class="material-icons opacity-10">alarm</i>
                </div>
                <span class="nav-link-text ms-1">Early Warning</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link text-white" to="/verify">
                <div
                  class="text-white text-center me-2 d-flex align-items-center justify-content-center"
                >
                  <i class="material-icons opacity-10">verified_user</i>
                </div>
                <span class="nav-link-text ms-1">Verifikasi KGB</span>
              </router-link>
            </li>
            <!-- <li class="nav-item">
                        <router-link class="nav-link text-white" to="/naik_pangkat">
                            <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                <i class="material-icons opacity-10">multiline_chart</i>
                            </div>
                            <span class="nav-link-text ms-1">Kenaikan Pangkat</span>
                        </router-link>
                    </li> -->
            <li class="nav-item">
              <router-link class="nav-link text-white" to="/sync">
                <div
                  class="text-white text-center me-2 d-flex align-items-center justify-content-center"
                >
                  <i class="material-icons opacity-10">sync</i>
                </div>
                <span class="nav-link-text ms-1">Sinkronisasi</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link text-white" to="/masakerja">
                <div
                  class="text-white text-center me-2 d-flex align-items-center justify-content-center"
                >
                  <i class="material-icons opacity-10">timer</i>
                </div>
                <span class="nav-link-text ms-1">Masa Kerja</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link text-white" to="/serah_terima">
                <div
                  class="text-white text-center me-2 d-flex align-items-center justify-content-center"
                >
                  <i class="material-icons opacity-10">folder_shared</i>
                </div>
                <span class="nav-link-text ms-1">Serah Terima Berkas</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link text-white" to="/settings">
                <div
                  class="text-white text-center me-2 d-flex align-items-center justify-content-center"
                >
                  <i class="material-icons opacity-10">settings</i>
                </div>
                <span class="nav-link-text ms-1">Pengaturan</span>
              </router-link>
            </li>
          </ul>
        </div>
      </aside>
    </Transition>
    <main
      class="main-content position-relative max-height-vh-100 h-100 border-radius-lg"
    >
      <!-- Navbar -->
      <nav
        class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl"
        id="navbarBlur"
        data-scroll="true"
      >
        <div class="container-fluid py-1 px-3">
          <nav aria-label="breadcrumb">
            <ol
              class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5"
            >
              <li class="breadcrumb-item text-sm">
                <a class="opacity-5 text-dark" href="javascript:;">Pages</a>
              </li>
              <li
                class="breadcrumb-item text-sm text-dark active"
                aria-current="page"
              >
                {{ this.$route.name }}
              </li>
            </ol>
            <h6 class="font-weight-bolder mb-0">{{ this.$route.name }}</h6>
          </nav>
          <div
            class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4"
            id="navbar"
          >
            <div class="ms-md-auto pe-md-3 d-flex align-items-center">
              <div class="input-group input-group-outline">
                <label class="form-label">Type here...</label>
                <input
                  type="text"
                  class="form-control"
                  onfocus="focused(this)"
                  onfocusout="defocused(this)"
                />
              </div>
            </div>
            <ul class="navbar-nav justify-content-end">
              <li class="nav-item ps-3 d-flex align-items-center">
                <a
                  @click="toggleMenu"
                  class="nav-link text-body p-0"
                  id="iconNavbarSidenav"
                >
                  <div class="sidenav-toggler-inner">
                    <i class="sidenav-toggler-line"></i>
                    <i class="sidenav-toggler-line"></i>
                    <i class="sidenav-toggler-line"></i>
                  </div>
                </a>
              </li>
              <li
                class="nav-item dropdown d-none pe-2 d-flex align-items-center"
              >
                <a
                  @click="toggleNotif"
                  ref="notifBtn"
                  class="nav-link text-body p-0 show"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="true"
                >
                  <i class="fa fa-bell cursor-pointer" aria-hidden="true"></i>
                </a>
                <transition name="slide-fade">
                  <ul
                    ref="notifArea"
                    v-if="showNotification"
                    class="dropdown-menu dropdown-menu-end px-2 py-3 me-sm-n4 show"
                    aria-labelledby="dropdownMenuButton"
                    data-bs-popper="static"
                    v-closable="{
                      exclude: ['notifBtn'],
                      handler: 'toggleNotif',
                    }"
                  >
                    <li class="mb-2">
                      <a
                        class="dropdown-item border-radius-md"
                        href="javascript:;"
                      >
                        <div class="d-flex py-1">
                          <div class="my-auto">
                            <img
                              src="https://placeimg.com/72/72/people"
                              class="avatar avatar-sm me-3"
                            />
                          </div>
                          <div
                            class="d-flex flex-column justify-content-center"
                          >
                            <h6 class="text-sm font-weight-normal mb-1">
                              <span class="font-weight-bold">New message</span>
                              from Laur
                            </h6>
                            <p class="text-xs text-secondary mb-0">
                              <i
                                class="fa fa-clock me-1"
                                aria-hidden="true"
                              ></i>
                              13 minutes ago
                            </p>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li class="mb-2">
                      <a
                        class="dropdown-item border-radius-md"
                        href="javascript:;"
                      >
                        <div class="d-flex py-1">
                          <div class="my-auto">
                            <img
                              src="https://placeimg.com/72/72/people"
                              class="avatar avatar-sm bg-gradient-dark me-3"
                            />
                          </div>
                          <div
                            class="d-flex flex-column justify-content-center"
                          >
                            <h6 class="text-sm font-weight-normal mb-1">
                              <span class="font-weight-bold">New album</span> by
                              Travis Scott
                            </h6>
                            <p class="text-xs text-secondary mb-0">
                              <i
                                class="fa fa-clock me-1"
                                aria-hidden="true"
                              ></i>
                              1 day
                            </p>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item border-radius-md"
                        href="javascript:;"
                      >
                        <div class="d-flex py-1">
                          <div
                            class="avatar avatar-sm bg-gradient-secondary me-3 my-auto"
                          >
                            <svg
                              width="12px"
                              height="12px"
                              viewBox="0 0 43 36"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                            >
                              <title>credit-card</title>
                              <g
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                              >
                                <g
                                  transform="translate(-2169.000000, -745.000000)"
                                  fill="#FFFFFF"
                                  fill-rule="nonzero"
                                >
                                  <g
                                    transform="translate(1716.000000, 291.000000)"
                                  >
                                    <g
                                      transform="translate(453.000000, 454.000000)"
                                    >
                                      <path
                                        class="color-background"
                                        d="M43,10.7482083 L43,3.58333333 C43,1.60354167 41.3964583,0 39.4166667,0 L3.58333333,0 C1.60354167,0 0,1.60354167 0,3.58333333 L0,10.7482083 L43,10.7482083 Z"
                                        opacity="0.593633743"
                                      ></path>
                                      <path
                                        class="color-background"
                                        d="M0,16.125 L0,32.25 C0,34.2297917 1.60354167,35.8333333 3.58333333,35.8333333 L39.4166667,35.8333333 C41.3964583,35.8333333 43,34.2297917 43,32.25 L43,16.125 L0,16.125 Z M19.7083333,26.875 L7.16666667,26.875 L7.16666667,23.2916667 L19.7083333,23.2916667 L19.7083333,26.875 Z M35.8333333,26.875 L28.6666667,26.875 L28.6666667,23.2916667 L35.8333333,23.2916667 L35.8333333,26.875 Z"
                                      ></path>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </div>
                          <div
                            class="d-flex flex-column justify-content-center"
                          >
                            <h6 class="text-sm font-weight-normal mb-1">
                              Payment successfully completed
                            </h6>
                            <p class="text-xs text-secondary mb-0">
                              <i
                                class="fa fa-clock me-1"
                                aria-hidden="true"
                              ></i>
                              2 days
                            </p>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <button
                        @click="registerNotification"
                        class="btn btn-primary"
                      >
                        Register Notification
                      </button>
                    </li>
                  </ul>
                </transition>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <!-- End Navbar -->
      <div class="container-fluid py-4">
        <router-view></router-view>
        <footer class="footer py-4">
          <div class="container-fluid">
            <div class="row align-items-center justify-content-lg-between">
              <div class="col-lg-6 mb-lg-0 mb-4">
                <div
                  class="copyright text-center text-sm text-muted text-lg-start"
                >
                  © 2022, made with <i class="fa fa-heart"></i> by
                  <a
                    href="https://www.creative-tim.com"
                    class="font-weight-bold"
                    target="_blank"
                    >Creative Tim</a
                  >
                  for a better web.
                </div>
              </div>
              <div class="col-lg-6">
                <ul
                  class="nav nav-footer justify-content-center justify-content-lg-end"
                >
                  <li class="nav-item">
                    <a
                      href="https://www.creative-tim.com"
                      class="nav-link text-muted"
                      target="_blank"
                      >Creative Tim</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      href="https://www.creative-tim.com/presentation"
                      class="nav-link text-muted"
                      target="_blank"
                      >About Us</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      href="https://www.creative-tim.com/blog"
                      class="nav-link text-muted"
                      target="_blank"
                      >Blog</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      href="https://www.creative-tim.com/license"
                      class="nav-link pe-0 text-muted"
                      target="_blank"
                      >License</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </main>
  </section>
</template>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>
<script>
export default {
  name: "LayoutComponent",
  props: {
    msg: String,
  },
  data: () => {
    return {
      showNotification: false,
      showMenu: true,
    };
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
      document.body.classList.toggle("g-sidenav-pinned");
    },
    toggleNotif() {
      this.showNotification = !this.showNotification;
    },
    registerNotification() {
      // try {
      // this.$messaging
      //     .requestPermission()
      //     .then(() => {
      //         console.log("Notification permission granted");
      this.$messaging
        .getToken()
        .then((token) => {
          window.console.log("token ", token);
        })
        .catch((err) => {
          console.log("Unable to get token ", err);
        });
      // } catch (e) {
      //     console.log(e);
      // }
    },
  },
};
</script>

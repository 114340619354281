
import firebase from "firebase/compat/app";
import "firebase/compat/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyByJ2zOYRdmByyXGMa-NVbAUDhm0s2OxEc",
  authDomain: "early-warning-pegawai.firebaseapp.com",
  projectId: "early-warning-pegawai",
  storageBucket: "early-warning-pegawai.appspot.com",
  messagingSenderId: "626632094210",
  appId: "1:626632094210:web:979690df336c4c312e2d1b",
  measurementId: "G-WV7RKP9E5D"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const messaging = firebase.messaging();
export default messaging;
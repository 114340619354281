<template>
  <section>
    <div class="container position-sticky z-index-sticky top-0">
      <div class="row">
        <div class="col-12">
          <!-- Navbar -->
          <nav
            class="navbar navbar-expand-lg blur border-radius-xl top-0 z-index-3 shadow position-absolute my-3 py-2 start-0 end-0 mx-4"
          >
            <div class="container-fluid ps-2 pe-0">
              <button
                class="navbar-toggler shadow-none ms-2"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navigation"
                aria-controls="navigation"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon mt-2">
                  <span class="navbar-toggler-bar bar1"></span>
                  <span class="navbar-toggler-bar bar2"></span>
                  <span class="navbar-toggler-bar bar3"></span>
                </span>
              </button>
              <div class="collapse navbar-collapse" id="navigation">
                <ul class="navbar-nav mx-auto">
                  <li class="nav-item">
                    <a
                      class="nav-link d-flex align-items-center me-2 active"
                      aria-current="page"
                    >
                      <i class="fa fa-chart-pie opacity-6 text-dark me-1"></i>
                      Report
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link me-2">
                      <i class="fa fa-user opacity-6 text-dark me-1"></i>
                      Hitung Masa Kerja
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link me-2">
                      <i class="fas fa-key opacity-6 text-dark me-1"></i>
                      Sign In
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <!-- End Navbar -->
        </div>
      </div>
    </div>
    <main class="main-content mt-0">
      <div
        class="page-header align-items-start min-vh-100"
        style="
          background-image: url('https://images.unsplash.com/photo-1497294815431-9365093b7331?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80');
        "
      >
        <span class="mask bg-gradient-dark opacity-6"></span>
        <div class="container my-auto">
          <div class="row">
            <div class="col-lg-4 col-md-8 col-12 mx-auto">
              <div class="card z-index-0 fadeIn3 fadeInBottom">
                <div
                  class="card-header p-0 position-relative mt-n4 mx-3 z-index-2"
                >
                  <div
                    class="bg-gradient-success shadow-primary border-radius-lg py-3 pe-1"
                  >
                    <h4
                      class="text-white font-weight-bolder text-center mt-2 mb-0"
                    >
                      Sign in
                    </h4>
                  </div>
                </div>
                <div class="card-body">
                  <form role="form" class="text-start">
                    <div v-if="message" class="alert alert-danger text-light">
                      {{ message }}
                    </div>
                    <div class="input-group input-group-outline my-3">
                      <input
                        placeholder="Username"
                        type="text"
                        v-model="form.username"
                        class="form-control"
                      />
                    </div>
                    <div class="input-group input-group-outline mb-3">
                      <input
                        placeholder="Password"
                        type="password"
                        v-model="form.password"
                        class="form-control"
                      />
                    </div>
                    <div class="text-center">
                      <button
                        :disabled="loading"
                        @click="login"
                        type="button"
                        class="btn bg-gradient-success w-100 my-4 mb-2"
                      >
                        <span
                          v-if="loading"
                          class="material-icons spin-animate"
                        >
                          hourglass_bottom
                        </span>
                        Sign In
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer class="footer position-absolute bottom-2 py-2 w-100">
          <div class="container">
            <div class="row align-items-center justify-content-lg-between">
              <div class="col-12 col-md-6 my-auto">
                <div
                  class="copyright text-center text-sm text-white text-lg-start"
                >
                  Template © 2022 made with
                  <i class="fa fa-heart" aria-hidden="true"></i> by
                  <a
                    href="https://www.creative-tim.com"
                    class="font-weight-bold text-white"
                    target="_blank"
                    >Creative Tim</a
                  >
                  for a better web.
                </div>
              </div>
              <div class="col-12 col-md-6"></div>
            </div>
          </div>
        </footer>
      </div>
    </main>
  </section>
</template>
<script>
const axios = require("axios").default;
export default {
  name: "LoginPage",
  props: {
    msg: String,
  },
  data: () => {
    return {
      form: {},
      loading: false,
      message: null,
    };
  },
  methods: {
    login() {
      this.loading = true;
      axios
        .post("/api/auth", this.form)
        .then((res) => {
          if (res.status == 200) {
            this.$emit("onLogged", true);
          } else if (res.status == 400) {
            this.message = res.data.message;
          }
        })
        .catch((e) => {
          this.message = e.response.data.message ?? e.message;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<template>
    <h4>Naik Pangkat Page</h4>
</template>
<script>
export default {
    name: 'NaikPangkatPage',
    props: {
        msg: String
    }
}
</script>
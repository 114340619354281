<template>
  <div id="app">
    <div
      v-if="loading"
      class="h-100 w-100 d-flex align-items-center justify-content-center"
      style="position: absolute"
    >
      <p>Harap Tunggu..</p>
    </div>
    <layout-component v-else-if="isLogin" />
    <login-page @onLogged="onLogged" v-else />
  </div>
</template>
<script>
import LoginPage from "./pages/LoginPage.vue";
import LayoutComponent from "./components/LayoutComponent.vue";
import axios from "axios";

export default {
  components: { LoginPage, LayoutComponent },
  data: () => {
    return {
      isLogin: false,
      loading: true,
    };
  },
  mounted() {
    this.checkSession();
  },
  methods: {
    checkSession() {
      this.loading = true;
      axios
        .get("/api/auth")
        .then(() => {
          this.isLogin = true;
        })
        .catch(() => {
          this.isLogin = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onLogged(statusLogin) {
      console.log(statusLogin);
      this.isLogin = statusLogin;
    },
  },
};
</script>
<style>
.spin-animate {
  -webkit-animation: spin-animate 2s infinite linear;
  animation: spin-animate 2s infinite linear;
}
@-webkit-keyframes spin-animate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin-animate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
</style>

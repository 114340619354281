<template>
  <div class="row">
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body pt-4 p-3">
          <div class="input-group input-group-outline mb-3">
            <input
              type="text"
              v-model="nip"
              class="form-control"
              placeholder="Isikan NIP Pegawai"
              aria-label="Recipient's username"
              aria-describedby="button-addon2"
            />
            <button
              @click="verify"
              :disabled="checking"
              class="btn btn-outline-success"
              style="margin: 0px"
              type="button"
            >
              <span v-if="checking" class="material-icons spin-animate">
                hourglass_bottom
              </span>
              Cek
            </button>
          </div>
          <v-select :options="listPegawai" @input="selectedPegawai"></v-select>
        </div>
      </div>
    </div>
    <div v-if="usulan.pejabat_pengesah" class="col-md-12 mt-4">
      <div
        class="alert alert-danger text-light"
        v-if="totalKenaikanGaji > 180000"
      >
        Total kenaikan gaji sebesar
        {{
          new Intl.NumberFormat("id-EN", {
            style: "currency",
            currency: "IDR",
          }).format(totalKenaikanGaji)
        }}. Harap diperiksa kembali !
      </div>
      <div class="card h-100 mb-4">
        <div class="card-header pb-0 px-3">
          <div class="row">
            <div class="col-md-6">
              <h6 class="mb-0">Draft Surat</h6>
            </div>
            <div class="col-md-6 text-end">
              <button
                @click="downloadDraft"
                :disabled="downloading"
                class="btn btn-primary"
              >
                <span v-if="downloading" class="material-icons spin-animate">
                  hourglass_bottom
                </span>
                Download Draft Surat
              </button>
              <button @click="simpanDraft" class="btn btn-info ms-1">
                <span v-if="saving" class="material-icons spin-animate">
                  hourglass_bottom
                </span>
                Simpan
              </button>
            </div>
          </div>
        </div>
        <div class="card-body p-3 row">
          <div class="col-6">
            <ul class="list-group list-group-flush">
              <li
                class="list-group-item d-flex justify-content-between ps-0 mb-2 row"
              >
                <div class="col-12">
                  <ul class="list-group ms-4">
                    <li class="list-group-item border-0 ps-0 text-sm">
                      <strong class="text-dark">Tanggal Surat:</strong>
                      <div class="input-group input-group-outline mt-1">
                        <date-picker
                          ref="tanggalSurat"
                          :class="{
                            'is-invalid border border-danger rounded':
                              validation.tanggal_surat_mysql,
                          }"
                          @change="setNoSurat"
                          :formatter="momentFormat"
                          value-type="format"
                          v-model="usulan.tanggal_surat"
                        ></date-picker>
                      </div>
                      <div
                        v-if="validation.tanggal_surat_mysql"
                        class="invalid-feedback d-block"
                      >
                        {{ validation.tanggal_surat_mysql }}
                      </div>
                    </li>
                    <li class="list-group-item border-0 ps-0 pt-0 text-sm">
                      <strong class="text-dark">No Surat:</strong>
                      <div
                        class="input-group input-group-outline mt-1"
                        :class="{
                          'is-invalid border border-danger rounded':
                            validation.nomor_sk,
                        }"
                      >
                        <input
                          v-model="usulan.nomor_sk"
                          type="text"
                          class="form-control"
                        />
                      </div>
                      <div
                        v-if="validation.nomor_sk"
                        class="invalid-feedback d-block"
                      >
                        {{ validation.nomor_sk }}
                      </div>
                    </li>
                    <li class="list-group-item border-0 ps-0 text-sm">
                      <strong class="text-dark">Pejabat Pengesah:</strong>
                      <div
                        class="input-group input-group-outline mt-1"
                        :class="{
                          'is-invalid border border-danger rounded':
                            validation.pejabat_pengesah,
                        }"
                      >
                        <input
                          v-model="usulan.pejabat_pengesah"
                          type="text"
                          class="form-control"
                        />
                      </div>
                      <div
                        v-if="validation.pejabat_pengesah"
                        class="invalid-feedback d-block"
                      >
                        {{ validation.pejabat_pengesah }}
                      </div>
                    </li>
                    <li class="list-group-item border-0 ps-0 text-sm">
                      <strong class="text-dark">Nama Pejabat:</strong>
                      <div
                        class="input-group input-group-outline mt-1"
                        :class="{
                          'is-invalid border border-danger rounded':
                            validation.nama_pejabat_pengesah,
                        }"
                      >
                        <input
                          v-model="usulan.nama_pejabat_pengesah"
                          type="text"
                          class="form-control"
                        />
                      </div>
                      <div
                        v-if="validation.nama_pejabat_pengesah"
                        class="invalid-feedback d-block"
                      >
                        {{ validation.nama_pejabat_pengesah }}
                      </div>
                    </li>
                    <li class="list-group-item border-0 ps-0 text-sm">
                      <strong class="text-dark">NIP Pejabat:</strong>
                      <div
                        class="input-group input-group-outline mt-1"
                        :class="{
                          'is-invalid border border-danger rounded':
                            validation.nip_pejabat_pengesah,
                        }"
                      >
                        <input
                          v-model="usulan.nip_pejabat_pengesah"
                          type="text"
                          class="form-control"
                        />
                      </div>
                      <div
                        v-if="validation.nip_pejabat_pengesah"
                        class="invalid-feedback d-block"
                      >
                        {{ validation.nip_pejabat_pengesah }}
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-6">
            <p class="fw-bold text-dark text-sm">Informasi Pegawai :</p>
            <ul class="list-group ms-4">
              <li class="list-group-item border-0 ps-0 pt-0 text-sm">
                <strong class="text-dark">Nama:</strong>
                {{ usulan.pegawai.nama }}
              </li>
              <li class="list-group-item border-0 ps-0 text-sm">
                <strong class="text-dark">NIP/Karpeg:</strong>
                {{ usulan.pegawai.nip }} / {{ usulan.pegawai.no_karpeg }}
              </li>
              <li class="list-group-item border-0 ps-0 text-sm">
                <strong class="text-dark">Pangkat/Jabatan:</strong>
                {{ usulan.pegawai.golongan.pangkat }} ({{
                  usulan.pegawai.golongan.golongan +
                  usulan.pegawai.golongan.ruang
                }}) / {{ usulan.pegawai.jabatan_fungsional }}
              </li>
              <li class="list-group-item border-0 ps-0 text-sm">
                <strong class="text-dark">Gaji Pokok Lama:</strong>
                {{ formatter.format(usulan.surat_terdahulu.gaji) }}
              </li>
            </ul>
            <p class="fw-bold text-dark text-sm mt-2">
              Atas dasar SK terakhir tentang gaji/pangkat yang telah ditetapkan:
            </p>
            <ul class="list-group ms-4">
              <li class="list-group-item border-0 ps-0 pt-0 text-sm">
                <strong class="text-dark">Oleh Pejabat:</strong>
                {{ usulan.surat_terdahulu.pejabat_pengesah }}
              </li>
              <li class="list-group-item border-0 ps-0 text-sm">
                <strong class="text-dark">Tanggal dan Nomor:</strong
                >{{ formatDate(usulan.surat_terdahulu.tanggal_surat) }},
                {{ usulan.surat_terdahulu.nomor_sk }}
              </li>
              <li class="list-group-item border-0 ps-0 text-sm">
                <strong class="text-dark"
                  >Tanggal Mulai berlaku gaji tsb. :</strong
                >
                {{ formatDate(usulan.surat_terdahulu.berlaku) }}
              </li>
              <li class="list-group-item border-0 ps-0 text-sm">
                <strong class="text-dark"
                  >Masa kerja golongan pada tanggal tersebut:</strong
                >
                {{
                  convertMonthToMasaKerja(
                    usulan.surat_terdahulu.masa_kerja_golongan
                  )
                }}
              </li>
            </ul>
            <p class="fw-bold text-dark text-sm mt-2">
              Diberikan kenaikan gaji berkala hingga memperoleh :
            </p>
            <ul class="list-group ms-4">
              <li class="list-group-item border-0 ps-0 pt-0 text-sm">
                <strong class="text-dark me-1">Gaji Pokok Baru:</strong>
                <vue-numeric
                  currency="Rp"
                  separator="."
                  v-model="usulan.gaji"
                ></vue-numeric>
              </li>
              <li
                class="list-group-item border-0 ps-0 text-sm d-flex align-items-center"
              >
                <strong class="text-dark me-1">Berdasarkan Masa Kerja: </strong>
                <div class="d-flex align-items-center">
                  <input
                    type="number"
                    min="0"
                    v-model="masaKerjaBaruTahun"
                    style="width: 40px"
                  />
                  Tahun
                  <input
                    type="number"
                    min="0"
                    max="12"
                    v-model="masaKerjaBaruBulan"
                    style="width: 40px"
                  />
                  Bulan
                </div>
              </li>
              <li class="list-group-item border-0 ps-0 text-sm">
                <strong class="text-dark me-1">Dalam Golongan:</strong>
                {{
                  usulan.pegawai.golongan.golongan +
                  usulan.pegawai.golongan.ruang
                }}
              </li>
              <li class="list-group-item border-0 ps-0 text-sm">
                <strong class="text-dark me-1">Mulai Berlaku:</strong>
                <date-picker
                  :class="{
                    'is-invalid border border-danger rounded':
                      validation.berlaku,
                  }"
                  ref="tanggalBerlaku"
                  :formatter="momentFormat"
                  value-type="format"
                  v-model="usulan.berlaku"
                ></date-picker>
                <div v-if="validation.berlaku" class="invalid-feedback d-block">
                  {{ validation.berlaku }}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import VueNumeric from "vue-numeric";
import FileSaver from "file-saver";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
moment.locale("id");
const monthName = {
  Januari: "01",
  Februari: "02",
  Maret: "03",
  April: "04",
  Mei: "05",
  Juni: "06",
  Juli: "07",
  Agustus: "08",
  September: "09",
  Oktober: "10",
  November: "11",
  Desember: "12",
};
export default {
  name: "VerifyKGBPage",
  props: {
    msg: String,
  },
  components: {
    DatePicker,
    VueNumeric,
    vSelect,
  },
  data: () => {
    return {
      checking: false,
      downloading: false,
      nip: "",
      monthName: monthName,
      validation: {},
      listPegawai: [],
      usulan: {
        pegawai: {
          golongan: {},
        },
        surat_terdahulu: {},
      },
      formatter: new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      }),
      momentFormat: {
        stringify: (date) => {
          return date ? moment(date).format("DD MMMM YYYY") : "";
        },
        parse: (value) => {
          if (moment(value, "YYYY-MM-DD").isValid()) {
            return value ? moment(value, "YYYY-MM-DD").toDate() : null;
          }
          let [tgl, bulan, tahun] = value.split(" ");
          return value
            ? moment(
                `${tahun}-${monthName[bulan]}-${tgl}`,
                "YYYY-MM-DD"
              ).toDate()
            : null;
        },
      },
      saving: false,
    };
  },
  mounted() {
    this.fetchPegawai();
  },
  computed: {
    masaKerjaBaruTahun: {
      get() {
        return this.usulan.masa_kerja_golongan
          ? Math.floor(this.usulan.masa_kerja_golongan / 12)
          : 0;
      },
      set(newValue) {
        this.usulan.masa_kerja_golongan =
          Number(newValue) * 12 + this.masaKerjaBaruBulan;
      },
    },
    masaKerjaBaruBulan: {
      get() {
        return this.usulan.masa_kerja_golongan
          ? this.usulan.masa_kerja_golongan % 12
          : 0;
      },
      set(newValue) {
        console.log(this.masaKerjaBaruTahun);
        console.log(this.masaKerjaBaruTahun * 12 + Number(newValue));
        this.usulan.masa_kerja_golongan =
          this.masaKerjaBaruTahun * 12 + Number(newValue);
      },
    },
    totalKenaikanGaji() {
      return this.usulan.gaji - this.usulan.surat_terdahulu.gaji;
    },
  },
  methods: {
    fetchPegawai() {
      axios
        .get(`/api/pegawai`)
        .then((res) => {
          if (res.data.data) {
            this.listPegawai = res.data.data;
          } else {
            if (res.data.message) {
              Swal.fire("Informasi", res.data.message, "warning");
            }
            this.model = {};
          }
        })
        .finally(() => {
          this.checking = false;
        });
    },
    formatDate(date) {
      return moment(date).format("DD MMMM YYYY");
    },
    selectedPegawai(item) {
      this.nip = item.nip;
      this.verify();
    },
    setNoSurat(date) {
      /* eslint-disable-next-line */
      let [tgl, bulan, tahun] = date.split(" ");
      if (this.usulan.format_surat) {
        this.usulan.nomor_sk = this.usulan.format_surat
          .replace("{tahun}", tahun)
          .replace("{bulan}", this.monthName[bulan]);
      }
    },
    convertMonthToMasaKerja(month) {
      let tahun = Math.floor(month / 12);
      let bulan = month % 12;
      return `${tahun} Tahun ${bulan} Bulan`;
    },
    downloadDraft() {
      let data = this.usulan;
      data.masa_kerja = this.convertMonthToMasaKerja(
        this.usulan.masa_kerja_golongan
      );
      data.surat_terdahulu.masa_kerja = this.convertMonthToMasaKerja(
        this.usulan.surat_terdahulu.masa_kerja_golongan
      );
      data.berlaku_formatted = this.formatDate(this.usulan.berlaku);
      data.surat_terdahulu.berlaku_formatted = this.formatDate(
        this.usulan.surat_terdahulu.berlaku
      );
      data.surat_terdahulu.tanggal_surat_formatted = this.formatDate(
        this.usulan.surat_terdahulu.tanggal_surat
      );
      this.downloading = true;
      axios({
        url: `api/verifykgb`,
        method: "POST",
        responseType: "blob",
        data: data,
      })
        .then((res) => {
          console.log(res);
          FileSaver.saveAs(res.data, "GajiBerkala.docx");
        })
        .finally(() => {
          this.downloading = false;
        });
    },
    simpanDraft() {
      this.saving = true;
      this.usulan.tanggal_surat_mysql = moment(
        this.$refs.tanggalSurat.currentValue
      ).format("YYYY-MM-DD");
      this.usulan.berlaku = moment(
        this.$refs.tanggalBerlaku.currentValue
      ).format("YYYY-MM-DD");
      this.validation = {};
      axios({
        url: `api/verifykgb/${this.usulan.id}`,
        method: "PUT",
        data: this.usulan,
      })
        .then((res) => {
          if (res.status == 200) {
            this.usulan.id = res.data.id;
            Swal.fire("Sukses", "Data berhasil disimpan !", "success");
          }
        })
        .catch((err) => {
          if (err.response.status == 422) {
            Swal.fire(
              "Tidak Valid",
              "Terdapat isian yang tidak valid. Harap cek kembali",
              "warning"
            );
            this.validation = err.response.data.validation;
          }
        })
        .finally(() => {
          this.saving = false;
        });
    },
    verify() {
      this.checking = true;
      axios
        .get(`/api/verifykgb/${this.nip}`)
        .then((res) => {
          if (res.data.data) {
            this.usulan = res.data.data;
          } else {
            if (res.data.message) {
              Swal.fire("Informasi", res.data.message, "warning");
            }
            this.usulan = {
              pegawai: {
                golongan: {},
              },
              surat_terdahulu: {},
            };
          }
        })
        .finally(() => {
          this.checking = false;
        });
    },
  },
};
</script>
<style>
.mx-input:disabled {
  color: black !important;
}
</style>

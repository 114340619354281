import Vue from "vue";
import VueRouter from "vue-router";
import HomePage from "../pages/HomePage";
import PegawaiPage from "../pages/PegawaiPage";
import SyncPage from "../pages/SyncPage";
import EarlyWarningPage from "../pages/EarlyWarningPage";
import NaikPangkatPage from "../pages/NaikPangkatPage";
import SettingsPage from "../pages/SettingsPage";
import VerifyKGBPage from "../pages/VerifyKGBPage";
import SerahTerimaPage from "../pages/SerahTerimaPage";
import MasaKerjaPage from "../pages/MasaKerjaPage";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/early_warning",
  },
  {
    path: "/masakerja",
    name: "MasaKerja",
    component: MasaKerjaPage,
  },
  {
    path: "/home",
    name: "Home",
    component: HomePage,
  },
  {
    path: "/pegawai",
    name: "Pegawai",
    component: PegawaiPage,
  },
  {
    path: "/sync",
    name: "Sinkronisasi",
    component: SyncPage,
  },
  {
    path: "/early_warning",
    name: "Early Warning",
    component: EarlyWarningPage,
  },
  {
    path: "/verify",
    name: "Verifikasi KGB",
    component: VerifyKGBPage,
  },
  {
    path: "/naik_pangkat",
    name: "Naik Pangkat",
    component: NaikPangkatPage,
  },
  {
    path: "/settings",
    name: "Pengaturan",
    component: SettingsPage,
  },
  {
    path: "/serah_terima",
    name: "SerahTerima",
    component: SerahTerimaPage,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "active bg-gradient-success",
});

export default router;
